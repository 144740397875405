import React, { memo, useCallback, useState } from 'react';
import { Tooltip } from 'antd';
import { message } from '../../components/Common/Message';
import { categoryApi } from '../../apis/categoryApi';
import PopConfirm from '@components/PopConfirm';
import IconDelete2 from '@icons/IconDelete2';

const BtnDelete = memo(({ uid, name, onSuccess }) => {
   const [isModalVisible, setIsModalVisible] = useState(false);

   const showModal = useCallback(() => {
      setIsModalVisible(true);
   }, []);

   const handleOk = useCallback(async () => {
      if (uid) {
         setIsModalVisible(false);
         message.loading('Deleting...', 0);
         const responseData = await categoryApi.deleteCategory(uid);
         setTimeout(async () => {
            if (responseData.isSuccess) {
               if (onSuccess) {
                  onSuccess();
               }
               message.success('Delete successfully.');
            } else {
               message.error(responseData.message);
            }
         }, 500);
      }
   }, [uid, onSuccess]);

   const onPopConfirmVisibleChange = useCallback((isOpen) => {
      setIsModalVisible(isOpen);
   }, []);

   return (
      <>
         <PopConfirm
            isOpen={isModalVisible}
            zIndex={4039}
            onVisibleChange={onPopConfirmVisibleChange}
            onConfirm={handleOk}
         >
            <Tooltip placement="bottom" title="Delete" color="black">
               <button
                  className={`button-icon action-delete ${
                     isModalVisible ? 'active' : ''
                  }`}
                  onClick={showModal}
               >
                  <IconDelete2 />
               </button>
            </Tooltip>
         </PopConfirm>
      </>
   );
});

export default BtnDelete;
