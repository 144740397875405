import React, {
   memo,
   useCallback,
   useContext,
   useEffect,
   useMemo,
   useRef,
   useState,
} from 'react';
import RequestModel from '../../../apis/models/RequestModel';
import constant from '../../../utils/constant';
import BtnAddCategory from '../../../views_admin/categories/BtnAddCategory';
import ContentLoading from '../../../views_admin/ContentLoading';
import BtnEdit from '../../../views_admin/categories/BtnEdit';
import BtnDelete from '../../../views_admin/categories/BtnDelete';
import { convertToDate } from '../../../utils/common';
import CommonTable from '../../../views_admin/components/CommonTable';
import { createCancelToken } from '../../../apis/ServiceController';
import { categoryApi } from '../../../apis/categoryApi';
import IconAdd2 from '../../../components/Icons/IconAdd2';
import Search from '../../../views_admin/layout/Search';
import Sort from '@views_admin/components/Sort';
import { categoryService } from '@services/categoryService';
import { SortType } from '@apis/enums/SortType';
import SEO from '@components/SEO';

const sortOptions = [
   {
      label: 'Last modified',
      value: 'updated_at',
   },
   {
      label: 'Date created',
      value: 'created_at',
   },
   {
      label: 'Name',
      value: 'name',
   },
   {
      label: 'Parent name',
      value: 'parent_name',
   },
];

const Categories = memo(() => {
   const [searchText, setSearchText] = useState('');

   const [filter, setFilter] = useState({
      page: 1,
      pageSize: constant.defaultPagesize,
      orderBy: 'updated_at',
      sortType: SortType.DESC,
   });
   const [data, setData] = useState({
      content: [],
      totalRows: 0,
   });
   const [loading, setLoading] = useState(true);
   const cancelToken = useRef();
   const categoriesCanceler = useRef(null);

   const reupdateStorageData = async () => {
      if (categoriesCanceler.current?.cancel) {
         categoriesCanceler.current.cancel();
      }
      categoriesCanceler.current = await categoryService.loadCategories({
         page: 1,
         page_size: constant.defaultPagesize,
         order_by: 'updated_at',
         sort_type: 'DESC',
      });
   };

   const onEditSuccess = useCallback(() => {
      setFilter((prevFilter) => ({
         ...prevFilter,
      }));
      reupdateStorageData();
   }, []);

   const reloadData = useCallback(() => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         page: 1,
      }));
      reupdateStorageData();
   }, []);

   const columns = useMemo(() => {
      return [
         {
            title: 'Name',
            dataIndex: 'name',
         },
         {
            title: 'Description',
            dataIndex: 'description',
            render: (text, record) => (
               <p className="description description-col-content">
                  {text && text.trim() ? text : <span className="nothing">--</span>}
               </p>
            ),
         },
         {
            title: 'Parent',
            dataIndex: 'parent',
            render: (text, record) => (
               <p className="description">
                  {text?.name ? text.name : <span className="nothing">--</span>}
               </p>
            ),
         },
         {
            title: 'Created date',
            dataIndex: 'created_at',
            render: (text, record) => (
               <span>
                  {text ? convertToDate(text) : <span className="nothing">--</span>}
               </span>
            ),
         },
         {
            title: 'Product counts',
            dataIndex: 'product_amount',
            render: (text, record) => (
               <span>{text || <span className="nothing">0</span>}</span>
            ),
         },
         {
            title: 'Action',
            key: 'action',
            width: 116,
            render: (text, record) => (
               <div className="admin-col-action">
                  <BtnEdit rowData={record} onSuccess={onEditSuccess} />
                  {!record.used && (
                     <BtnDelete
                        uid={record.uid}
                        name={record.name}
                        onSuccess={reloadData}
                     />
                  )}
               </div>
            ),
         },
      ];
   }, [onEditSuccess, reloadData]);

   useEffect(() => {
      if (cancelToken.current) {
         cancelToken.current.cancel();
      }
      setLoading(true);
      cancelToken.current = createCancelToken();
      const loadCategories = async () => {
         const requestBody = new RequestModel([
            filter,
            {
               search: searchText.trim(),
            },
         ]);
         const responseData = await categoryApi.getCategoriesMatrix(
            requestBody,
            cancelToken.current?.token
         );
         if (responseData.isSuccess) {
            const { content, totalRows } = responseData.data;
            setData((prevData) => ({
               ...prevData,
               content: content || [],
               totalRows: totalRows,
            }));
         }
         setLoading(false);
      };
      loadCategories();

      return () => {
         if (cancelToken.current) {
            cancelToken.current.cancel();
         }
      };
   }, [filter, searchText]);

   const refresh = useCallback(() => {
      setFilter({
         ...filter,
         orderBy: '',
         sortType: '',
         page: 1,
      });
      reupdateStorageData();
   }, [filter]);

   const onTableChange = useCallback(({ pagination, sorter }) => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         page: pagination?.page || prevFilter.page,
         pageSize: pagination?.pageSize || prevFilter.pageSize,
      }));
   }, []);

   const handleSort = useCallback((orderBy, sortType) => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         sortType,
         orderBy,
      }));
   }, []);

   return (
      <>
         <SEO title={'Categories - Admin'} />
         <div className="content-head">
            <div className="content-head__left">
               <label className="title">Categories</label>
            </div>
            <div className="content-head__right">
               <BtnAddCategory
                  className="outline-btn btn-add-new-item"
                  onSuccess={refresh}
               >
                  <i>
                     <IconAdd2 />
                  </i>
                  Add Category
               </BtnAddCategory>
            </div>
         </div>
         <div className="content-body">
            <div className="admin-table">
               <ContentLoading isLoading={loading} />
               <div className="admin-table__header">
                  <div className="admin-table__header--left">
                     <Search
                        placeholder="Search in categories"
                        onSearch={(v) => setSearchText(v)}
                     />
                  </div>
                  <div className="admin-table__header--right">
                     <Sort
                        orderBy={filter.orderBy}
                        sortType={filter.sortType}
                        options={sortOptions}
                        onChange={handleSort}
                     />
                  </div>
               </div>
               {
                  <CommonTable
                     rowKey="uid"
                     unit="Categories"
                     dataSource={data.content}
                     columns={columns}
                     page={filter.page}
                     pageSize={filter.pageSize}
                     totalRows={data.totalRows}
                     onChange={onTableChange}
                  />
               }
            </div>
         </div>
      </>
   );
});

export default Categories;
