import React, { memo, useCallback, useState } from 'react';
import { Form } from 'antd';
import RequestModel from '../../apis/models/RequestModel';
import { categoryApi } from '../../apis/categoryApi';
import { message } from '../../components/Common/Message';
import EditingDrawer from '../components/EditingDrawer';
import { InputType } from '@utils/enums/InputType';
import SelectCategory from './SelectCategory';

const formDefinitions = [
   {
      name: 'name',
      label: 'Category',
      placeholder: 'Category name',
      rules: [
         {
            required: true,
            message: 'Please input your Category name.',
         },
      ],
      isRequired: true,
   },
   {
      name: 'description',
      label: 'Description',
      type: InputType.TEXTAREA,
      placeholder: 'Description',
   },
   {
      name: 'parent',
      label: 'Parent',
      placeholder: 'Parent category',
      Component: SelectCategory,
   },
];

const BtnAddCategory = memo(({ className, onSuccess, ...ownProps }) => {
   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
   const [form] = Form.useForm();
   const [submitting, setSubmitting] = useState(false);

   const showDrawer = useCallback(() => {
      setIsDrawerOpen(true);
   }, []);
   const onClose = useCallback(() => {
      setIsDrawerOpen(false);
   }, []);

   const onSubmit = useCallback(
      async (values) => {
         await setSubmitting(true);
         const body = {
            name: values?.name || '',
            description: values?.description || '',
            parent_uid: values?.parent?.uid || '',
         };
         const requestBody = new RequestModel(body);
         const responseData = await categoryApi.createCategory(requestBody);
         if (responseData.isSuccess) {
            onSuccess();
            message.success('Create new category successfully.');
            onClose();
            form.resetFields();
         } else {
            message.error(responseData.message);
         }
         setSubmitting(false);
      },
      [onSuccess, form, onClose]
   );

   return (
      <>
         <button className={className} onClick={showDrawer}>
            {ownProps.children || 'Add new'}
         </button>

         <EditingDrawer
            title="Create a new Category"
            isOpen={isDrawerOpen}
            form={form}
            formDefinitions={formDefinitions}
            submitting={submitting}
            onClose={onClose}
            onSubmit={onSubmit}
         />
      </>
   );
});

export default BtnAddCategory;
