import { adminService } from "../services/adminService";
import { tokenUtil } from "../utils/token";
import { apiConstant } from "./constant";
import { fetch } from "./ServiceController";

const getCategoriesMatrix = async (body, cancelToken) => {
   return fetch({
      method: 'post',
      url: apiConstant.GET_CATEGORIES_MATRIX,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
}

const createCategory = async (body, cancelToken) => {
   return fetch({
      method: 'post',
      url: apiConstant.CREATE_CATEGORY,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
}

const updateCategory = async (uid, body, cancelToken) => {
   return fetch({
      method: 'put',
      url: apiConstant.UPDATE_CATEGORY,
      params: {
         uid,
      },
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
}

const deleteCategory = async (uid, cancelToken) => {
   return fetch({
      method: 'delete',
      url: apiConstant.DELETE_CATEGORY,
      params: {
         uid,
      },
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
}

export const categoryApi = {
   getCategoriesMatrix,
   createCategory,
   updateCategory,
   deleteCategory,
}
