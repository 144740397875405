import React, { memo, useCallback, useEffect, useState } from 'react';
import { Tooltip, Form } from 'antd';
import IconEdit from '../../components/Icons/IconEdit';
import EditingDrawer from '../components/EditingDrawer';
import { categoryApi } from '../../apis/categoryApi';
import RequestModel from '../../apis/models/RequestModel';
import { message } from '../../components/Common/Message';
import _forIn from 'lodash/forIn';
import { InputType } from '@utils/enums/InputType';
import SelectCategory from './SelectCategory';

const formDefinitions = [
   {
      name: 'name',
      label: 'Category',
      placeholder: 'Category name',
      rules: [
         {
            required: true,
            message: 'Please input your Category name.',
         },
      ],
      isRequired: true,
   },
   {
      name: 'description',
      label: 'Description',
      type: InputType.TEXTAREA,
      placeholder: 'Description',
   },
   {
      name: 'parent',
      label: 'Parent',
      placeholder: 'Parent category',
      Component: SelectCategory,
   },
];

const BtnEdit = memo(({ rowData, onSuccess }) => {
   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
   const [form] = Form.useForm();
   const [submitting, setSubmitting] = useState(false);

   const showDrawer = useCallback(() => {
      const valueObj = {};
      _forIn(rowData, (value, key) => {
         valueObj[key] = value;
      });
      form.setFieldsValue(valueObj);
      setIsDrawerOpen(true);
   }, [form, rowData]);

   const onClose = useCallback(() => {
      setIsDrawerOpen(false);
   }, []);

   useEffect(
      () => {
         form.setFieldsValue({
            name: rowData?.name || '',
            description: rowData?.description || '',
            parent: {
               uid: rowData?.parent?.uid || '',
               name: rowData?.parent?.name || '',
            },
         });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [rowData]
   );

   const onSubmit = useCallback(
      async (values) => {
         if (rowData.uid) {
            await setSubmitting(true);
            const body = {
               name: values?.name || '',
               description: values?.description || '',
               parent_uid: values?.parent?.uid || '',
            };
            const responseData = await categoryApi.updateCategory(
               rowData.uid,
               new RequestModel(body)
            );
            if (responseData.isSuccess) {
               onSuccess();
               message.success(`You successfully updated category data`);
               onClose();
               form.resetFields();
            } else {
               message.error(responseData.message);
            }
            setSubmitting(false);
         }
      },
      [onSuccess, rowData.uid, form, onClose]
   );

   return (
      <>
         <Tooltip placement="bottom" title="Edit" color="black">
            <button className="button-icon action-normal" onClick={showDrawer}>
               <IconEdit />
            </button>
         </Tooltip>
         <EditingDrawer
            title="Edit Category"
            isOpen={isDrawerOpen}
            form={form}
            formDefinitions={formDefinitions}
            submitting={submitting}
            currentUid={rowData.uid}
            onClose={onClose}
            onSubmit={onSubmit}
         />
      </>
   );
});

export default BtnEdit;
